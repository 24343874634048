import React, { useRef, useState, useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"

import SearchIcon from "@mui/icons-material/Search"
import MenuIcon from "@mui/icons-material/Menu"
import EmailIcon from "@mui/icons-material/Email"
import PhoneIcon from "@mui/icons-material/Phone"
import PinDropIcon from "@mui/icons-material/PinDrop"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"

import "./navbar.css"
import RedCorner from "../../assets/red-corner.svg"
import logo from "../../assets/logo.webp"

import axios from "../axios"
import NavbarRange from "./NavbarRange"
import IconButton from "../mui/IconButton"
import useSiteData from "../../hooks/useSiteData"
import { urlSlug } from "../utility/UsefulFunctions"
import SkeletonWrapper from "../mui/SkeletonWrapper"

const MaxItemsPerBrand = 3

/**
 * Top nav bar
 */
const NavBar = () => {
    const navigate = useNavigate()
    const searchInputRef = useRef(null)
    const { bannerMessage, openingTimesWeekdays, openingTimesSunday, showInterestFreeCredit } = useSiteData()

    const [categories, setCategories] = useState(null)
    const [chosenCatId, setChosenCatId] = useState(null)

    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const [searchParams, setSearchParams] = useState(null)

    const [hoverCatId, setHoverCatId] = useState(null)

    const openSearch = () => {
        searchInputRef.current.focus()
    }

    const stopPropagation = (e) => {
        e.stopPropagation()
    }

    /**
     * Get the categories
     */
    const getCategories = async (abortController) => {
        try {
            const res = await axios.get("/api/category/navbar", { signal: abortController?.signal })
            setCategories(res.data)
        } catch (e) {
            if (!abortController?.signal?.aborted) console.error(e)
        }
    }

    /**
     * Get the passed banner message in a span
     */
    const getBannerMessageSpan = (message) => {
        const messageArr = [message]

        let count = 0 // 128 or above
        if (message.length < 64) count++
        if (message.length < 32) count++

        for (let i = 0; i < count; i++) {
            messageArr.push(message)
        }

        return (
            <>
                {messageArr.map((msg, index) => (
                    <span key={index}>{msg}</span>
                ))}
            </>
        )
    }

    const closeSideBar = () => {
        setIsSidebarOpen(false)
        setHoverCatId(null)
    }

    /**
     * Init page
     */
    useEffect(() => {
        const abortController = new AbortController()
        getCategories(abortController)
        // Clean up function
        return () => abortController.abort()
    }, [])

    const bannerMessageSpan = bannerMessage ? getBannerMessageSpan(bannerMessage) : null

    return (
        <>
            {bannerMessageSpan && (
                <div className='banner-message' role='marquee'>
                    <p>{bannerMessageSpan}</p>
                    <p aria-hidden='true'>{bannerMessageSpan}</p>
                </div>
            )}
            <div className='navbar-wrapper'>
                <div className='navbar-top-mobile flex-center-item'>
                    <div className='flex-row-spacebetween full-width full-height'>
                        <search className='flex-row-center-vertical flex-100 flex-gap-16'>
                            <MenuIcon sx={{ fontSize: "32px" }} className='icon' onClick={() => setIsSidebarOpen(!isSidebarOpen)} />
                            <form
                                role='search'
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    closeSideBar()
                                    navigate(`/search/${encodeURIComponent(searchParams)}`)
                                }}
                            >
                                <div className='mobile-search-wrapper flex-center-item'>
                                    <input
                                        ref={searchInputRef}
                                        id='mobile-search'
                                        name='mobile-search'
                                        className='search-input'
                                        aria-label='Search'
                                        onChange={(e) => setSearchParams(e.target.value)}
                                    />
                                    <SearchIcon className='search-icon' sx={{ fontSize: "26px", zIndex: 3 }} onClick={openSearch} />
                                    <div className='bottom-search-border' />
                                </div>
                            </form>
                        </search>
                        <div className='logo-image-wrapper'>
                            <Link to='/'>
                                <img
                                    src={logo}
                                    crossOrigin='anonymous'
                                    referrerPolicy='same-origin'
                                    aria-label='Ramdens Home Interior'
                                    alt='Ramdens Home Interior'
                                />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* MOBILE NAV BAR */}
                <div className='popout-nav-wrapper'>
                    <div className={isSidebarOpen ? "popout-nav" : "popout-nav-hide"}>
                        <div className='flex-column relative flex-gap-none m-popout-content'>
                            <div className='m-opening-times padding-16 flex-column flex-gap-8 full-width'>
                                <p className='m-section-header'>Opening Times</p>
                                <div className='flex-row flex-gap-32'>
                                    <div className='flex-column flex-gap-none'>
                                        <p className='ot-days'>Monday - Saturday</p>
                                        <p className='ot-times'>{openingTimesWeekdays}</p>
                                    </div>
                                    <div className='flex-column flex-gap-none'>
                                        <p className='ot-days'>Sunday</p>
                                        <p className='ot-times'>{openingTimesSunday}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='full-width m-cat-wrapper'>
                                {categories && (
                                    <div className='m-category-wrapper'>
                                        <div className='m-category '>
                                            <div className='flex-row-spacebetween'>
                                                <Link to='/latest-products' onClick={() => closeSideBar()}>
                                                    <p>New In</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {categories &&
                                    categories.map((c) => {
                                        return (
                                            <div key={c.id} className='m-category-wrapper'>
                                                <div
                                                    className='m-category'
                                                    onClick={() => {
                                                        chosenCatId === c.id ? setChosenCatId(null) : setChosenCatId(c.id)
                                                    }}
                                                >
                                                    <div className='flex-row-spacebetween'>
                                                        <p>{c.name}</p>
                                                        {chosenCatId === c.id ? (
                                                            <KeyboardArrowUpIcon sx={{ color: "rgba(0,0,0,.5)" }} />
                                                        ) : (
                                                            <KeyboardArrowDownIcon sx={{ color: "rgba(0,0,0,.5)" }} />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className={chosenCatId === c.id ? "m-subcat-wrapper" : "m-subcat-wrapper-closed"}>
                                                    {chosenCatId === c.id &&
                                                        c.subCategories.map((sc) => {
                                                            return (
                                                                <div key={sc.uuid} className='m-subcat'>
                                                                    <Link to={`/store/${c.routeName}/${sc.routeName}`} onClick={() => closeSideBar()}>
                                                                        <p>{sc.name}</p>
                                                                    </Link>
                                                                </div>
                                                            )
                                                        })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                {categories && (
                                    <div className='m-category-wrapper'>
                                        <div className='m-category m-category-special-offers'>
                                            <div className='flex-row-spacebetween'>
                                                <Link to='/special-offers' onClick={() => closeSideBar()}>
                                                    <p>Special Offers</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className='m-nav-contact flex-row-spacearound full-width'>
                                <div className='m-contact-wrapper flex-gap-4'>
                                    <Link to={`/contact`} onClick={() => closeSideBar()}>
                                        <EmailIcon className='red-icon' sx={{ fontSize: "22px" }} />
                                        <p className='m-contact-text'>Email</p>
                                    </Link>
                                </div>
                                <a href={`tel:${process.env.REACT_APP_PHONE_LINK}`} className='m-contact-wrapper flex-gap-4'>
                                    <PhoneIcon className='red-icon' sx={{ fontSize: "22px" }} />
                                    <p className='m-contact-text'>Phone</p>
                                </a>
                                <div className='m-contact-wrapper flex-gap-4'>
                                    <Link to={`/find-us`} onClick={() => closeSideBar()}>
                                        <PinDropIcon className='red-icon' sx={{ fontSize: "22px" }} />
                                        <p className='m-contact-text'>Location</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={isSidebarOpen ? "bg-blur" : "bg-blur-hide"} onClick={() => setIsSidebarOpen(false)} />
                </div>

                {/* DESKTOP NAV BAR */}
                <header className='navbar-top-desktop' role='banner'>
                    <div className='flex-row-center-vertical flex-spacebetween full-height'>
                        {/* Left cell */}
                        <div className='flex-row flex-gap-32 flex-spacearound nb-left'>
                            <div className='nav-contact flex-row-center-vertical flex-gap-8'>
                                <div className='m-contact-wrapper'>
                                    <Link to='/contact' aria-label='Contact us' onClick={() => closeSideBar()}>
                                        <IconButton hintText='Contact us'>
                                            <EmailIcon className='red-icon' sx={{ fontSize: "22px" }} alt='Contact us' />
                                        </IconButton>
                                    </Link>
                                </div>
                                <div className='m-contact-wrapper'>
                                    <Link to='/contact' aria-label='Contact us' onClick={() => closeSideBar()}>
                                        <IconButton hintText='Contact us'>
                                            <PhoneIcon className='red-icon' sx={{ fontSize: "22px" }} alt='Contact us' />
                                        </IconButton>
                                    </Link>
                                </div>
                                <div className='m-contact-wrapper'>
                                    <Link to='/find-us' aria-label='Contact us' onClick={() => closeSideBar()}>
                                        <IconButton hintText='Find us'>
                                            <PinDropIcon className='red-icon' sx={{ fontSize: "22px" }} alt='Find us' />
                                        </IconButton>
                                    </Link>
                                </div>

                                <div className='m-contact-wrapper '>
                                    <a
                                        href={process.env.REACT_APP_FACEBOOK}
                                        aria-label='Facebook'
                                        target='_blank'
                                        rel='noreferrer'
                                        onClick={() => closeSideBar()}
                                    >
                                        <IconButton hintText='Facebook'>
                                            <FacebookIcon className='red-icon' sx={{ fontSize: "22px" }} alt='Facebook' />
                                        </IconButton>
                                    </a>
                                </div>
                                <div className='m-contact-wrapper '>
                                    <a
                                        href={process.env.REACT_APP_INSTAGRAM}
                                        aria-label='Instagram'
                                        target='_blank'
                                        rel='noreferrer'
                                        onClick={() => closeSideBar()}
                                    >
                                        <IconButton hintText='Instagram'>
                                            <InstagramIcon className='red-icon' sx={{ fontSize: "22px" }} alt='Instagram' />
                                        </IconButton>
                                    </a>
                                </div>
                            </div>
                            <div className='flex-row-center-vertical flex-gap-16'>
                                <div className='flex-column flex-gap-none'>
                                    <p className='ot-days'>Monday - Saturday</p>
                                    <p className='ot-times'>{openingTimesWeekdays}</p>
                                </div>
                                <div className='flex-column flex-gap-none'>
                                    <p className='ot-days'>Sunday</p>
                                    <p className='ot-times'>{openingTimesSunday}</p>
                                </div>
                            </div>
                        </div>
                        {/* Middle cell */}
                        <div className='logo-wrapper'>
                            <Link to='/'>
                                <img
                                    src={logo}
                                    crossOrigin='anonymous'
                                    referrerPolicy='same-origin'
                                    aria-label='Ramdens Home Interior'
                                    alt='Ramsdens Home Interior'
                                />
                            </Link>
                        </div>
                        {/* Right cell */}
                        <search className='nb-right' itemScope itemType='https://schema.org/WebSite'>
                            <meta itemProp='name' content={`${process.env.REACT_APP_META_SITENAME}`} />
                            <meta itemProp='url' content={`${process.env.REACT_APP_SITE_URI}`} />
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    navigate(`/search/${encodeURIComponent(searchParams)}`)
                                }}
                                role='search'
                                itemScope
                                itemProp='potentialAction'
                                itemType='https://schema.org/SearchAction'
                            >
                                <meta itemProp='target' content={`${process.env.REACT_APP_SITE_URI}search/{search_term_string}`} />
                                <div className='mobile-search-wrapper flex-center-item'>
                                    <input
                                        type='search'
                                        itemProp='query-input'
                                        name='search_term_string'
                                        id='search_term_string'
                                        aria-label='Search'
                                        className='search-input'
                                        onChange={(e) => setSearchParams(e.target.value)}
                                    />
                                    <SearchIcon className='search-icon' sx={{ fontSize: "26px" }} onClick={openSearch} />
                                    <div className='bottom-search-border' />
                                </div>
                            </form>
                        </search>
                    </div>
                </header>
                <nav className='navbar-bottom-wrapper' itemScope itemProp='https://schema.org/SiteNavigationElement'>
                    <ul className='navbar-bottom' role='menu'>
                        <li role='menuitem' className='nb-cat-button nb-cat-button-latest-products full-height flex-center-item'>
                            <Link className='nb-cat-button-link' to='/latest-products' onClick={() => closeSideBar()}>
                                <p itemProp='name'>New In</p>
                            </Link>
                        </li>

                        {categories ? (
                            categories.map((c) => {
                                const isCatHovered = hoverCatId === c.id

                                return (
                                    <li
                                        key={c.id}
                                        className='nb-cat-button'
                                        role='menuitem'
                                        onMouseEnter={() => setHoverCatId(c.id)}
                                        onMouseLeave={() => setHoverCatId(null)}
                                        // onClick={() => navigate(`/store/${c.routeName}`)}
                                    >
                                        <Link to={`/store/${c.routeName}`} onClick={() => closeSideBar()} className='nb-cat-button-link'>
                                            <p itemProp='name'>{c.name}</p>
                                        </Link>

                                        <div className={isCatHovered ? "nb-dropdown" : "nb-dropdown-hidden"}>
                                            <div className='flex-row fit-container'>
                                                <div className='flex-column flex-wrap flex-gap-16 fit-height width-75' onClick={(e) => stopPropagation(e)}>
                                                    {c.subCategories?.map((sc) => {
                                                        return <NavBarBrand key={sc.uuid} category={c} subCategory={sc} onClick={() => closeSideBar()} />
                                                    })}
                                                </div>
                                                <div className='flex-column-spacebetween'>
                                                    <NavbarRange isVisible={isCatHovered} categoryId={c.id} onClick={() => closeSideBar()} />
                                                    {showInterestFreeCredit ? (
                                                        <>
                                                            <Link to={"/interest-free-credit"} className='nb-ifc-wrapper'>
                                                                <p>
                                                                    <b>Interest Free Credit Available</b>
                                                                </p>
                                                                <p>Click here for more details</p>

                                                                <div className='text-small-print fw-normal text-align-center text-wrap-balance'>
                                                                    Subject to affordability, age and status, minimum spend applies.
                                                                </div>
                                                            </Link>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        ) : (
                            <SkeletonWrapper
                                count={8}
                                width='8vw'
                                callback={(skeleton) => {
                                    return (
                                        <div className='nb-cat-button'>
                                            <div className='nb-cat-button-link'>{skeleton}</div>
                                        </div>
                                    )
                                }}
                            />
                        )}

                        <li role='menuitem' className='nb-cat-button nb-cat-button-special-offers full-height flex-center-item'>
                            <Link className='nb-cat-button-link' to='/special-offers' onClick={() => closeSideBar()}>
                                <p itemProp='name'>Special Offers</p>
                            </Link>
                        </li>
                    </ul>
                    <img
                        className='red-corner'
                        crossOrigin='anonymous'
                        referrerPolicy='same-origin'
                        aria-hidden={true}
                        src={RedCorner}
                        alt=''
                        onMouseEnter={() => setHoverCatId(hoverCatId)}
                    />
                </nav>
            </div>
        </>
    )
}

/**
 * Naviation brand list
 */
function NavBarBrand({ category, subCategory, onClick }) {
    const hasNavBrand =
        subCategory.navBrand1 !== null ||
        subCategory.navBrand2 !== null ||
        subCategory.navBrand3 !== null ||
        subCategory.navProperty1 !== null ||
        subCategory.navProperty2 !== null ||
        subCategory.navProperty3 !== null

    return (
        <div className='flex-row-spacebetween'>
            <div className='nb-sc-group flex-column flex-gap-none'>
                <Link to={`/store/${category.routeName}/${subCategory.routeName}`} onClick={onClick}>
                    <p className='nb-sc-title'>{subCategory.name}</p>
                </Link>
                <ul className='nb-brand-title flex-column flex-gap-none' role='list'>
                    {hasNavBrand ? (
                        <>
                            {subCategory.navBrand1 !== null && (
                                <NavBarBrandLink brand={subCategory.navBrand1} category={category} subCategory={subCategory} onClick={onClick} />
                            )}
                            {subCategory.navBrand2 !== null && (
                                <NavBarBrandLink brand={subCategory.navBrand2} category={category} subCategory={subCategory} onClick={onClick} />
                            )}
                            {subCategory.navBrand3 !== null && (
                                <NavBarBrandLink brand={subCategory.navBrand3} category={category} subCategory={subCategory} onClick={onClick} />
                            )}
                            {subCategory.navProperty1 !== null && (
                                <NavBarPropertyLink property={subCategory.navProperty1} category={category} subCategory={subCategory} onClick={onClick} />
                            )}
                            {subCategory.navProperty2 !== null && (
                                <NavBarPropertyLink property={subCategory.navProperty2} category={category} subCategory={subCategory} onClick={onClick} />
                            )}
                            {subCategory.navProperty3 !== null && (
                                <NavBarPropertyLink property={subCategory.navProperty3} category={category} subCategory={subCategory} onClick={onClick} />
                            )}
                        </>
                    ) : (
                        subCategory.brands?.map((scb, i) => {
                            if (i >= MaxItemsPerBrand) return null
                            return <NavBarBrandLink key={i} brand={scb} category={category} subCategory={subCategory} onClick={onClick} />
                        })
                    )}
                    <li>
                        <Link to={`/store/${category.routeName}/${subCategory.routeName}`} className='nb-brand-text' key={"more-brands"} onClick={onClick}>
                            <b>See All {subCategory.name}</b>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

/**
 * Brand Link
 * @param {Object} props - The component props
 * @param {Object} props.category
 * @param {Object} props.subCategory
 * @param {Object} props.brand
 * @param {function|undefined} props.onClick
 */
function NavBarBrandLink({ category, subCategory, brand, onClick }) {
    // brand = { uuid:"2b8444f3-e989-4327-b49e-186c6c5e0627", name:"OBABY"}
    return (
        <li>
            <Link to={`/brand/${category.routeName}/${subCategory.routeName}/${brand.uuid}/${urlSlug(brand.name)}`} onClick={onClick} className='nb-brand-text'>
                {brand.name}
            </Link>
        </li>
    )
}

/**
 * Property Link
 * @param {Object} props - The component props
 * @param {Object} props.category
 * @param {Object} props.subCategory
 * @param {Object} props.property
 * @param {function|undefined} props.onClick
 */
function NavBarPropertyLink({ category, subCategory, property, onClick }) {
    // property = { value: 4, label: "Colour: White" }
    return (
        <li>
            <Link
                to={`/property/${category.routeName}/${subCategory.routeName}/${property.value}/${urlSlug(property.label)}`}
                onClick={onClick}
                className='nb-brand-text'
            >
                {property.label}
            </Link>
        </li>
    )
}

export default NavBar
